<template>
    <div v-if="regionUsers.length > 0">
        <div class="card">
            <h1>Regions</h1>
            <div class="table-responsive">
                <table class="card-table table">
                    <thead>
                    <tr>
                        <th>Region Name</th>
                        <th v-if="isAdmin">Default?</th>
                        <th v-if="isAdmin"/>
                        <th v-if="isAdmin"/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="regionUser in regionUsers" :key="regionUser.id">
                        <td><router-link :to="{ name: 'RegionShow', params: { id: regionUser.region_id } }">{{ regionUser.region_name }}</router-link></td>
                        <td v-if="isAdmin">{{ regionUser.default }}</td>
                        <td v-if="isAdmin"><button @click="openEditRegionUserModal(regionUser)" type="button" class="btn btn-primary">Edit</button></td>
                        <td v-if="isAdmin"><button @click="openRemoveRegionUserModal(regionUser.id)" type="button" class="btn btn-danger">Remove</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <div class="btn-group float-right" v-if="isAdmin">
                    <button @click="openNewRegionUserModal" type="button" class="btn btn-success">Associate User to new region</button>
                </div>
            </div>
        </div>

        <!-- Remove Region User Modal        -->
        <b-modal v-model="removeRegionUserModal" header-bg-variant="danger" header-text-variant="light" title="Confirm Delete" ok-variant="danger" ok-title="Delete" @ok="destroyRegionUser" centered>
            <p>Are you sure you want to remove this user from this region?</p>
        </b-modal>

        <!-- New Region User Modal        -->
        <b-modal v-model="newRegionUserModal" header-bg-variant="info" header-text-variant="light" title="Add User to a Region" ok-variant="info" ok-title="Associate" @ok="createRegionUser" centered>
            <danger-alert :errors="errors"/>

            <div class="form-group text-left">
                <label>Region</label>
                <v-select class="user-input"
                          :clearable="false"
                          :options="regionMap"
                          index="value"
                          v-model="regionUser.region_id"
                />

                <div class="form-group text-left">
                    <label>Default?</label>
                    <b-checkbox v-model="regionUser.default" />
                </div>
            </div>

        </b-modal>

        <b-modal v-model="editRegionUserModal" header-bg-variant="info" header-text-variant="light" title="Edit Region User" ok-variant="info" ok-title="Save" @ok="updateRegionUser" centered>
            <danger-alert :errors="errors"/>

            <div class="form-group text-left">
                <div class="form-group text-left">
                    <label>Default?</label>
                    <b-checkbox v-model="regionUser.default" />
                </div>
            </div>

        </b-modal>

    </div>
    <div v-else>
      <div class="card">
        <h1>No Regions Found</h1>
        <div class="card-footer">
          <div class="btn-group float-right">
            <button @click="openNewRegionUserModal" type="button" class="btn btn-success">Associate User to new region</button>
          </div>
        </div>
      </div>

      <b-modal v-model="newRegionUserModal" header-bg-variant="info" header-text-variant="light" title="Add User to a Region" ok-variant="info" ok-title="Associate" @ok="createRegionUser" centered>
        <danger-alert :errors="errors"/>

        <div class="form-group text-left">
          <label>Region</label>
          <v-select class="user-input"
                    :clearable="false"
                    :options="regionMap"
                    index="value"
                    v-model="regionUser.region_id"
          />

          <div class="form-group text-left">
            <label>Default?</label>
            <b-checkbox v-model="regionUser.default" />
          </div>
        </div>

      </b-modal>
    </div>

</template>

<script>
import DangerAlert from '@/common/components/danger-alert.vue';
import { RegionService } from '@/common/services/region.service';
import { RegionUserService } from '@/common/services/region_user.service';
import HelperMethods from '@/common/mixins/helper_methods';
import PermissionService from '@/common/services/user_permissions.service';
import _ from 'lodash';

export default {
  name: 'RegionUsers',
  mixins: [HelperMethods],
  props: {
    user: Object,
  },
  components: {
    DangerAlert,
  },
  data() {
    return {
      regionUsers: [],
      regions: [],
      errors: [],
      regionMap: [],
      regionUser: {},
      newRegionUserModal: false,
      removeRegionUserModal: false,
      editRegionUserModal: false,
      regionUserId: null,
      isAdmin: false,
      debug: false,
    };
  },
  created() {
    this.initialLoad();
    this.isAdmin = PermissionService.isAdmin();
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    initialLoad() {
      this.reset();
      this.closeAllModals();
      Promise.all([this.getRegions(), this.getRegionUsers()])
        .then(() => {
          this.mapRegions();
        });
    },
    reset() {
      this.regions = [];
      this.regionUsers = [];
      this.errors = [];
      this.regionUser = {
        user_id: this.user.id,
        region_id: null,
        default: true,
      };
      this.regionMap = [];
    },
    getRegions() {
      const params = {
        per_page: 1000,
        page: 1,
      };
      return RegionService.query(params)
        .then((response) => {
          this.regions = response.data;
          this.log('getRegions -> RegionService', response);
        })
        .catch((err) => { this.errors = err.response.data.error; });
    },
    getRegionUsers() {
      const params = {
        per_page: 1000,
        page: 1,
        user_id: this.user.id,
      };
      return RegionUserService.query(params)
        .then((response) => {
          this.regionUsers = _.sortBy(response.data, ['region_name']);
          this.log('getRegionUsers -> RegionUserService', response);
        })
        .catch((err) => { this.errors = err.response.data.error; });
    },
    destroyRegionUser() {
      this.log('deleteRegionUser', { region_user_id: this.regionUserId });
      if (this.regionUserId) {
        RegionUserService.destroy(this.regionUserId)
          .then((response) => {
            this.log('destroyRegionUser -> Destroyed', response);
            this.initialLoad();
          })
          .catch((err) => { this.errors = err.response.data.error; });
      }
    },
    createRegionUser() {
      this.log('createRegionUser', { region_user: this.regionUser });
      const params = {
        user_id: this.user.id,
        region_id: this.regionUser.region_id,
        is_archived: true,
      };
      let checkRegionUser = null;

      RegionUserService.query(params)
        .then((response) => {
          checkRegionUser = response.data.pop();
          return this.createOrUnarchiveUser(checkRegionUser);
        });
    },
    createOrUnarchiveUser(user) {
      if (user != null) {
        RegionUserService.update(user.id, { is_archived: false, default: this.regionUser.default })
          .then((response) => {
            this.log('createRegionUser -> created', response);
            this.initialLoad();
          });
      } else {
        RegionUserService.create(this.regionUser)
          .then((response) => {
            this.log('createRegionUser -> created', response);
            this.initialLoad();
          })
          .catch((err) => { this.errors = err.response.data.error; });
      }
    },
    updateRegionUser() {
      this.log('createRegionUser', { region_user_id: this.regionUserId, region_user: this.regionUser });
      RegionUserService.update(this.regionUserId, this.regionUser)
        .then((response) => {
          this.log('createRegionUser -> created', response);
          this.initialLoad();
        })
        .catch((err) => { this.errors.push(err.response.data.error); });
    },
    closeAllModals() {
      this.log('closeAllModals');
      this.newRegionUserModal = false;
      this.removeRegionUserModal = false;
      this.editRegionUserModal = false;
      this.regionUserId = null;
      this.regionUser = {
        user_id: this.user.id,
        region_id: null,
        default: true,
      };
    },
    openEditRegionUserModal(regionUser) {
      this.closeAllModals();
      if (regionUser) {
        this.regionUserId = regionUser.id;
        this.regionUser = {
          user_id: this.user.id,
          region_id: regionUser.region_id,
          default: regionUser.default,
        };
        this.editRegionUserModal = true;
      }
      this.log('openEditRegionUserModal', {
        region_user: regionUser,
        global_region_user: this.regionUser,
        region_user_id: this.regionUserId,
      });
    },
    openRemoveRegionUserModal(regionUserId) {
      this.log('openRemoveRegionUserModal', regionUserId);
      this.closeAllModals();
      this.regionUserId = regionUserId;
      this.removeRegionUserModal = true;
    },
    openNewRegionUserModal() {
      this.log('openNewRegionUserModal');
      this.closeAllModals();
      this.newRegionUserModal = true;
    },
    mapRegions() {
      this.log('mapRegions', { regions: this.regions, region_users: this.regionUsers });
      this.regionMap = [];
      const usedRegions = [];
      if (this.regions && this.regions.length > 0) {
        for (let i = 0; i < this.regions.length; i += 1) {
          const region = this.regions[i];
          if (region) {
            const option = {};
            option.label = region.name;
            option.value = region.id;
            this.regionMap.push(option);
          }
        }
        this.log('mapRegions -> mapped', this.regionMap);
      }
      if (this.regionUsers && this.regionUsers.length > 0) {
        for (let i = 0; i < this.regionUsers.length; i += 1) {
          usedRegions.push(this.regionUsers[i].region_id);
        }
        this.log('mapRegions -> usedRegions', usedRegions);

        for (let i = 0; i < usedRegions.length; i += 1) {
          this.regionMap = this.findAndSpliceObjectFromArray(this.regionMap, 'value', usedRegions[i]);
        }
        this.log('mapRegions -> trimmed', this.regionMap);
      }
    },
  },
};

</script>

<style scoped>

</style>
