import JwtService from './jwt.service';

const token = JwtService.parseJwt();

export const UserPermissionsService = {
  isAdmin() {
    return token.role === 'Admin';
  },
  isViewer() {
    return token.role === 'Sales Coach' || token.role === 'Service Coach';
  },
  isUserOwner(id) {
    return Number(id) === token.sub;
  },
  isSalesCoach() {
    return token.role === 'Sales Coach';
  },
  isServiceCoach() {
    return token.role === 'Service Coach';
  },
};

export default UserPermissionsService;
