import ApiService from './api.service';

export const UserService = {
  query(params) {
    return ApiService.query('users', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('users', slug);
  },
  create(params) {
    return ApiService.post('users', { user: params });
  },
  update(slug, params) {
    return ApiService.update('users', slug, { user: params });
  },
  destroy(slug) {
    return ApiService.delete(`users/${slug}`);
  },
  merge_users(slug, params) {
    return ApiService.post(`users/${slug}/merge_users`, { user: params });
  },
};

export default UserService;
