<template>
  <div v-if="user">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4 id="top">User: <small>{{ user.email }}</small> </h4>
      <h4 class=""><b-badge v-if="isOwner" variant="primary">It's You</b-badge></h4>
    </div>
    <div class="card">
      <div class="rainbow-background"></div>
      <div class="table-responsive">
        <table class="card-table table">
          <tbody class="text-left">
          <tr>
            <th scope="col">Name</th>
            <td>{{ user.first_name }} {{ user.last_name }}</td>
          </tr>
          <tr>
            <th scope="col">E-mail</th>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <th scope="col">Role</th>
            <td>{{ user.role }}</td>
          </tr>
          <tr v-if="isAdmin">
            <th scope="col">Enable Email Notifications</th>
            <td>{{ user.enable_email }}</td>
          </tr>
          <tr v-if="user.role === 'Trainee'">
            <th scope="col">Trainee Role</th>
            <td>{{ user.trainee_role ? user.trainee_role.title : "No Trainee Role" }}</td>
          </tr>
          <tr>
            <th scope="col">Default Region</th>
            <td>{{ user.region ? user.region.name : "No Region" }}</td>
          </tr>
          <tr>
            <th scope="col">Phase</th>
            <td>{{ user.phase ? user.phase.title : "No Phase" }}</td>
          </tr>
          <tr v-if="isAdmin">
            <th scope="col">Is Archived?</th>
            <td>{{ user.is_archived }}</td>
          </tr>
          <tr v-if="isAdmin">
            <th scope="col">Created At</th>
            <td>{{ new Date(user.created_at).toDateString() }}, {{ new Date(user.created_at).toLocaleTimeString() }}</td>
          </tr>
          <tr v-if="isAdmin">
            <th scope="col">Updated At</th>
            <td>{{ new Date(user.updated_at).toDateString() }}, {{ new Date(user.created_at).toLocaleTimeString() }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <div class="btn-group float-right" v-if="isAdmin">
          <a v-if="isAdmin" @click="$router.go(-1)" tag="button" class="btn btn-secondary">Back</a>
          <router-link :to="{ name: 'UserEdit' }" tag="button" class="btn btn-primary">Edit</router-link>
          <button v-if="isAdmin && user.role === 'Trainee'" v-on:click="$refs.userMergeModal.show()" type="button" class="btn btn-warning">Merge</button>
          <button v-if="isAdmin" v-on:click="$refs.userDeleteModal.show()" type="button" class="btn btn-danger">Delete</button>
        </div>
      </div>
    </div>

    <region-users :user="user"/>

    <!-- Modal Component -->
    <b-modal ref="userDeleteModal" class="userDeleteModal" header-bg-variant="danger" header-text-variant="light" title="Confirm Delete" ok-variant="danger" ok-title="Delete" @ok="deleteUser" centered>
      <p>Are you sure you want to delete this user?</p>
    </b-modal>

    <!-- Merge Component -->
    <b-modal ref="userMergeModal" header-bg-variant="warning" header-text-variant="dark" title="Merge User" ok-variant="warning" ok-title="Merge" @ok="mergeUser" centered>
      <p>Please search for the user you would like to merge into this user.</p>
      <v-select class="user-input" :clearable="false" :options="searchResults" @search="searchTrainees" label="full_name" v-model="selectedUser" placeholder="Select User to Merge"></v-select>

      <br>

      <div v-if="selectedUser">
        <h5>Trainee Role</h5>
        <p v-if="selectedUser.trainee_role.title === user.trainee_role.title">User roles are the same.</p>
        <p v-else>Select which trainee role to keep when merging users.</p>

        <div v-if="selectedUser.trainee_role !== user.trainee_role" class="clearfix clear align-content-center center">
          <b-badge class="d-inline-block mr-2 cursor" @click="selectedTraineeRole = user.trainee_role" :variant="selectedTraineeRole === user.trainee_role ? 'success' : 'secondary'">{{ user.trainee_role.title }}</b-badge>
          <b-badge class="d-inline-block cursor" @click="selectedTraineeRole = selectedUser.trainee_role" :variant="selectedTraineeRole === selectedUser.trainee_role ? 'success' : 'secondary'">{{ selectedUser.trainee_role.title }}</b-badge>
        </div>

        <br>

        <h5>Region</h5>
        <p v-if="selectedUser.region.name === user.region.name">Regions are the same.</p>
        <p v-else>Select which region to keep when merging users.</p>

        <div v-if="selectedUser.region.name !== user.region.name" class="clearfix clear align-content-center center">
          <b-badge class="d-inline-block mr-2 cursor" @click="selectedUserRegion = user.region" :variant="selectedUserRegion === user.region ? 'success' : 'secondary'">{{ user.region.name }}</b-badge>
          <b-badge class="d-inline-block cursor" @click="selectedUserRegion = selectedUser.region" :variant="selectedUserRegion === selectedUser.region ? 'success' : 'secondary'">{{ selectedUser.region.name }}</b-badge>
        </div>

        <br>

        <h5>Phase</h5>
        <p v-if="selectedUser.phase.title === user.phase.title">Phases are the same.</p>
        <p v-else>Select which phase to keep when merging users.</p>

        <div v-if="selectedUser.phase.title !== user.phase.title" class="clearfix clear align-content-center center">
          <b-badge class="d-inline-block mr-2 cursor" @click="selectedUserPhase = user.phase" :variant="selectedUserPhase === user.phase ? 'success' : 'secondary'">{{ user.phase.title }}</b-badge>
          <b-badge class="d-inline-block cursor" @click="selectedUserPhase = selectedUser.phase" :variant="selectedUserPhase === selectedUser.phase ? 'success' : 'secondary'">{{ selectedUser.phase.title }}</b-badge>
        </div>
      </div>

    </b-modal>
  </div>
  <loading-overlay v-else/>
</template>

<script>
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import { UserService } from '@/common/services/user.service';
import JwtService from '@/common/services/jwt.service';
import PermissionsService from '@/common/services/user_permissions.service';
import { RegionUserService } from '@/common/services/region_user.service';
import { FilterService } from '@/common/services/filter.service';
import RegionUsers from '@/common/components/region_users.vue';

export default {
  name: 'UserShow',
  components: {
    LoadingOverlay,
    RegionUsers,
  },
  data() {
    return {
      id: this.$route.params.id,
      user: null,
      currentUser: null,
      errors: [],
      isOwner: false,
      isAdmin: false,
      selectedUser: null,
      searchResults: [],
      filters: FilterService.defaultData(),
      selectedTraineeRole: null,
      selectedUserRegion: null,
      selectedUserPhase: null,
      regionUsers: [],
    };
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.user = null;
      this.currentUser = null;
      this.errors = [];
      this.isOwner = false;
      this.isAdmin = false;
      this.selectedUser = null;
      this.searchResults = [];
      this.filters = FilterService.defaultData();
      this.selectedTraineeRole = null;
      this.selectedUserRegion = null;
      this.selectedUserPhase = null;
      this.regionUsers = [];
      this.getUser();
      this.getUsers();
    },
    getUser() {
      const token = JwtService.parseJwt();
      UserService.get(this.id)
        .then((response) => {
          this.user = response.data;
          this.selectedTraineeRole = this.user.trainee_role;
          this.selectedUserRegion = this.user.region;
          this.selectedUserPhase = this.user.phase;

          this.isAdmin = PermissionsService.isAdmin();

          if (this.user.id !== token.id) {
            if (token.role !== 'Admin') {
              this.$router.go(-1);
            }
          }
        })
        .then(() => {
          this.getRegions();
        });
    },
    deleteUser() {
      UserService.destroy(this.$route.params.id)
        .then((response) => {
          this.$router.push({ name: 'UserIndex' });
          this.$notify({
            title: `Deleted ${response.data.first_name} ${response.data.last_name}`,
            text: 'Successfully deleted User.',
            type: 'success',
            width: 350,
          });
        });
    },
    mergeUser() {
      UserService.merge_users(this.user.id, {
        trainee_role: this.selectedTraineeRole,
        region: this.selectedUserRegion,
        phase: this.selectedUserPhase,
        user_to_merge: this.selectedUser,
      })
        .then(() => {
          this.reset();

          this.$notify({
            title: 'Successfully Merged Users',
            text: 'Successfully merged users together!',
            type: 'success',
            width: 350,
          });
        });
    },
    searchTrainees(search, loading) {
      // This is called when the user enters anything in the v-select field, and calls the below search method
      loading(true);
      this.searchedTraineeName = search;
      this.search(loading, search, this);
    },
    search: _.debounce((loading, search, self) => {
      const selfRef = self;

      // This method makes an api request on Tags looking for a tag name containing the search param
      let params;
      if (search.length > 1) {
        params = FilterService.build({ page: 1, per_page: 10 }, { first_name_or_last_name_cont: search });
        selfRef.selectedPhase = null;
        selfRef.selectedTraineeRole = null;
        selfRef.selectedRegion = null;
        selfRef.traineeExists = false;
      } else {
        params = FilterService.build({ page: 1, per_page: 10 }, { s: 'created_at desc' });
        selfRef.selectedPhase = null;
        selfRef.selectedTraineeRole = null;
        selfRef.selectedRegion = null;
        selfRef.traineeExists = true;
      }
      UserService.query(params)
        .then(({ data }) => {
          selfRef.searchResults = data;
          if (selfRef.searchResults.length === 0) {
            selfRef.disabledSelections = false;
          }
          loading(false);
        });
    }, 350),
    getUsers() {
      this.filters = FilterService.defaultData();
      this.filters.id_not_eq = this.id;
      const params = FilterService.build({ page: 1, per_page: 500 }, this.filters);
      UserService.query(params)
        .then(({ data }) => {
          this.searchResults = data;
        });
    },
    getRegions() {
      RegionUserService.query({ user_id: this.user.id }).then((response) => {
        this.regionUsers = response.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/users";
</style>
