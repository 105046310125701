import _ from 'lodash';

export default {
  data() {
    return {
      debug: true,
    };
  },
  created() {
    if (process.env.NODE_ENV === 'Production') {
      this.debug = false;
    }
  },
  computed: {
  },
  methods: {
    convertTimeStringToValue(timeString) {
      let value = 0;
      if (timeString === 'Non Working') {
        value = 0.0;
      } else if (timeString === 'Half Day') {
        value = 0.5;
      } else if (timeString === 'Full Day') {
        value = 1.0;
      }
      return value;
    },
    calculateWeekTotal(timeCardActivity) {
      const days = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'];
      let total = 0.0;

      for (let i = 0; i < 7; i += 1) {
        const column = 'day_'.concat(days[i]);
        total += this.convertTimeStringToValue(timeCardActivity[column]);
      }

      this.log('helper -> calculateWeekTotal', { timeCardActivity, total }, false);
      return total;
    },
    getDateString(date) {
      let monthCheck = '';
      let dateCheck = '';
      if (date.getMonth() + 1 < 10) monthCheck = '0';
      if (date.getDate() < 10) dateCheck = '0';
      return `${date.getFullYear()}-${monthCheck}${date.getMonth() + 1}-${dateCheck}${date.getDate()}`;
    },
    noDayFormattedDate(date, day) {
      const d = new Date(date);
      d.setDate(d.getDate() + day);
      const dFromatted = `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`;
      return dFromatted;
    },
    formattedDate(date, day) {
      let dayString = '';
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      this.log('formattedDate', { date, days }, false);
      const d = new Date(date);
      d.setDate(d.getDate() + day);
      if (day !== 0) dayString = days[day - 1];
      const dFromatted = `${dayString} ${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`;
      return dFromatted;
    },
    getTrainingDates(timeCardActivity) {
      let day = 1;
      let dateFound = false;
      if (this.checkForWorkingStatus(timeCardActivity.day_one)) {
        day = 1;
        dateFound = true;
      } else if (this.checkForWorkingStatus(timeCardActivity.day_two) && dateFound === false) {
        day = 2;
        dateFound = true;
      } else if (this.checkForWorkingStatus(timeCardActivity.day_three) && dateFound === false) {
        day = 3;
        dateFound = true;
      } else if (this.checkForWorkingStatus(timeCardActivity.day_four) && dateFound === false) {
        day = 4;
        dateFound = true;
      } else if (this.checkForWorkingStatus(timeCardActivity.day_five) && dateFound === false) {
        day = 5;
        dateFound = true;
      } else if (this.checkForWorkingStatus(timeCardActivity.day_six) && dateFound === false) {
        day = 6;
        dateFound = true;
      } else if (this.checkForWorkingStatus(timeCardActivity.day_seven) && dateFound === false) {
        day = 7;
      }
      this.log('getTrainingDates', timeCardActivity);
      this.log('getTrainingDates meta', { timeCardActivity, day, dateFound });
      return this.formattedDate(timeCardActivity.time_card.week_start_date, day);
    },
    checkForWorkingStatus(status) {
      this.log('checkForWorkingStatus', status);
      let result = false;
      switch (status) {
        case 'Full Day':
          result = true;
          break;
        case 'Half Day':
          result = true;
          break;
        default:
          result = false;
      }
      return result;
    },
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    isNotEmpty(obj) {
      let value = true;
      if (_.isEmpty(obj)) {
        value = false;
      }
      return value;
    },
    convertDate(date) {
      // src: https://stackoverflow.com/questions/1531093/how-do-i-get-the-current-date-in-javascript
      let formatedDate = null;
      if (date) {
        const today = new Date(date);
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        formatedDate = `${yyyy}-${mm}-${dd}`;
      }
      return formatedDate;
    },
    getWeekStart() {
      // src: https://stackoverflow.com/a/4156516
      // that link gets mondays, we want sundays
      let weekStart = null;
      const date = new Date();
      if (date) {
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 0);
        weekStart = new Date(date.setDate(diff));
      }
      return weekStart;
    },
    regionName(region) {
      let name = null;
      if (region) {
        // eslint-disable-next-line
        name = region.name;
      }
      return name;
    },
    log(msg, data = null, display = true) {
      if (this.debug === true && display === true) {
        /* eslint-disable no-console */
        console.debug(`${msg}`, _.cloneDeep(data));
      }
    },
    /**
     * Finds and removes an element from an array
     * @param array
     * @param value
     * @returns {{length}}
     */
    findAndSpliceValueFromArray(array, value) {
      if (array && array.length) {
        for (let i = 0; i < array.length; i += 1) {
          if (array[i] === value) {
            array.splice(i, 1);
            break;
          }
        }
      }
      return array;
    },
    /**
     * Finds and removes an object from array based on property
     * @param array
     * @param property
     * @param value
     * @returns {{length}|*}
     */
    findAndSpliceObjectFromArray(array, property, value) {
      if (array && array.length) {
        for (let i = 0; i < array.length; i += 1) {
          if (array[i][property] === value) {
            array.splice(i, 1);
            break;
          }
        }
      }
      return array;
    },
  },
};
